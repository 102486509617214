import React, { useState, useEffect } from 'react';
import Container from 'Components/Container';
import { isNull } from 'lodash';
import config from 'Config/firebase';
import { createStripeCheckoutSessionForPreSale, getPreSaleCollection } from 'Services/api';
import Loading from 'Components/Loading';
import Input from 'Shared/Input';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import CardIcon from './assets/card.svg';
import PreSaleLayout from './PreSaleLayout';
import { usePreSaleStyles } from './PreSaleStyle';

// eslint-disable-next-line no-undef
const stripe = Stripe(config.stripe);

const ExternalMint = () => {
  const classess = usePreSaleStyles();
  const queryParameters = new URLSearchParams(window.location.search);
  const initialQuantity = queryParameters.get('quantity') || 1;

  const [isLoading, setLoading] = useState(null);
  const [pageQuantity, setPageQuantity] = useState(initialQuantity);
  const [collectionInfo, setCollectionInfo] = useState(null);
  const [serverError, setServerError] = useState(null);
  const collectionId = queryParameters.get('id');

  // Fee states
  const [basePrice, setBasePrice] = useState(0);
  const [creditCardFees, setCreditCardFees] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);

  const calculateFees = value => {
    if (!collectionInfo?.fees) return;

    const quantity = Number(value);
    setBasePrice((collectionInfo.fees.basePrice * quantity).toFixed(2));

    // Combine platform fee and stripe fee
    const combinedFees = (collectionInfo.fees.platformFee + collectionInfo.fees.stripeFee) * quantity;
    setCreditCardFees(combinedFees.toFixed(2));

    setTotalPrice((collectionInfo.fees.totalFee * quantity).toFixed(2));
  };

  useEffect(() => {
    const fetchCollection = async () => {
      setLoading(true);
      try {
        const collection = await getPreSaleCollection({ id: collectionId });
        setCollectionInfo(collection.data);
      } catch (error) {
        console.error('Error fetching collection:', error);
      }
      setLoading(false);
    };

    fetchCollection();
  }, [collectionId]);

  useEffect(() => {
    if (collectionInfo) {
      calculateFees(pageQuantity);
    }
  }, [collectionInfo, pageQuantity]);

  const sendToStripeCheckout = async order => {
    try {
      const stripeCheckoutSessionId = await createStripeCheckoutSessionForPreSale({
        order,
        cancelUrl: window.location.href,
      });

      const result = await stripe.redirectToCheckout({ sessionId: stripeCheckoutSessionId });
      if (result.error) {
        setServerError(result.error.message);
      }
    } catch (error) {
      console.log('Error:', error);
      setServerError(error.response?.data?.error || 'An error occurred during checkout');
    }
  };

  const validationSchema = Yup.object().shape({
    quantity: Yup.number()
      .required('Quantity is required')
      .min(1, 'Minimum quantity is 1')
      .max(collectionInfo?.maxPerOrder || 4, `Maximum quantity is ${collectionInfo?.maxPerOrder || 4}`),
  });

  if (isNull(isLoading)) {
    return null;
  }

  if (isLoading) {
    return (
      <PreSaleLayout>
        <Loading
          type={'bubbles'}
          color={'#2bc8c5'}
          height={'80px'}
          width={'80px'}
          containerHeight={'40vh'}
          containerWidth={'100%'}
          message={'Loading'}
        />
      </PreSaleLayout>
    );
  }

  return (
    <PreSaleLayout>
      {collectionInfo?.partnerLogo && (
        <div className={classess.partnerLogoContainer}>
          <img src={collectionInfo.partnerLogo} alt="Partner's Logo" className={classess.partnerLogo} />
        </div>
      )}
      <Container>
        <div className={classess.mintNft}>
          <div className={classess.mintNftCol}>
            <div className={classess.mintNftHeader}>
              <div>
                <h4 className={classess.heading}>Pre-Sale</h4>
                <span className={classess.authLink}>{collectionInfo?.name || 'Collection'}</span>
              </div>
            </div>

            {collectionInfo?.image && (
              <div className={classess.collectionImageContainer}>
                <img
                  src={collectionInfo.image}
                  alt={collectionInfo.name || 'Collection'}
                  className={classess.collectionImage}
                />
              </div>
            )}

            {collectionInfo && (
              <div className={classess.availabilityInfo}>
                <p>
                  <strong>Total Supply: </strong> {collectionInfo.total || 0}
                  <br />
                  <strong>Sold: </strong> {collectionInfo.sold || 0}
                  <br />
                  <strong>In Progress: </strong> {collectionInfo.inProgress || 0}
                  <br />
                  <strong>Available: </strong>{' '}
                  {(collectionInfo.total || 0) - (collectionInfo.sold || 0) - (collectionInfo.inProgress || 0)}
                </p>
              </div>
            )}

            {pageQuantity > 0 && (
              <p>
                <strong>Base Price: </strong> ${basePrice}
                <br />
                <strong>Credit Card Fees: </strong> ${creditCardFees}
                <div className={classess.statementNote}>*Will appear on your statement as Ownerfy</div>
                <br />
                <strong>Total: </strong> ${totalPrice}
              </p>
            )}

            <div className={classess.formContainer}>
              <Formik
                initialValues={{ quantity: initialQuantity }}
                validationSchema={validationSchema}
                onSubmit={async values => {
                  setLoading(true);
                  try {
                    await sendToStripeCheckout({
                      collectionId: collectionId,
                      quantity: Number(values.quantity),
                      maxPerOrder: collectionInfo?.maxPerOrder || 4,
                    });
                  } catch (err) {
                    console.error('Error:', err);
                    setServerError(err.response?.data?.error || 'Failed to process checkout');
                  }
                  setLoading(false);
                }}
              >
                {({ errors, touched, values, handleChange }) => (
                  <Form>
                    <Input
                      name="quantity"
                      label="Quantity"
                      error={errors.quantity}
                      value={values.quantity}
                      touched={touched.quantity}
                      onChange={e => {
                        const value = e.target.value;
                        handleChange(e);
                        setPageQuantity(value);
                        setServerError(null);
                      }}
                    />
                    {serverError && <div className={classess.errorMessage}>{serverError}</div>}
                    <div className={classess.formFooter}>
                      <span className={classess.btnLabel}>Purchase With</span>
                      <div className={classess.btnWrappaer}>
                        <button type="submit" className={classess.mintBtn}>
                          <img src={CardIcon} alt="Credit Card" style={{ marginRight: '10px' }} />
                          <span>Buy Now</span>
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Container>
    </PreSaleLayout>
  );
};

export default ExternalMint;
