import React from 'react';
import Container from 'Components/Container';
import PreSaleLayout from './PreSaleLayout';
import { usePreSaleStyles } from './PreSaleStyle';

const Success = () => {
  const classes = usePreSaleStyles();

  return (
    <PreSaleLayout>
      <Container>
        <div className={classes.mintNft}>
          <div className={classes.mintNftCol}>
            <div className={classes.mintNftHeader}>
              <h4 className={classes.heading}>Purchase Successful!</h4>
            </div>
            <div className={classes.availabilityInfo}>
              <p style={{ fontStyle: 'italic' }}>
                You will recieve a confirmation email and follow up instructions to the email your provided in checkout.
              </p>
              <p style={{ fontStyle: 'italic' }}>
                <br />
                This charge will appear as Ownerfy on your credit card statement.{' '}
              </p>
              <p style={{ fontStyle: 'italic' }}>
                <br />
                If you have any questions, please contact <a href="mailto:trio@xyz">trio@xyz</a>.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </PreSaleLayout>
  );
};

export default Success;
