import { makeStyles } from '@material-ui/core/styles';

const usePreSaleStyles = makeStyles(theme => ({
  preSaleContainer: {
    width: '100%',
    backgroundColor: '#333333',
    boxSizing: 'border-box',
    paddingBottom: '80px',
    paddingTop: '60px',
    minHeight: '100vh',
  },
  partnerLogoContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginBottom: '10px',
  },
  partnerLogo: {
    maxWidth: '120px',
    maxHeight: '40px',
    objectFit: 'contain',
  },
  mintNft: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    fontFamily: 'Lato',
    marginTop: '42px',
    '@media (max-width: 768px)': {
      flexWrap: 'wrap',
    },
  },
  mintNftCol: {
    flexBasis: '50%',
    backgroundColor: '#fff',
    borderRadius: '20px',
    marginBottom: '20px',
    padding: '60px',
    marginRight: '12px',
    boxSizing: 'border-box',
    '@media (max-width: 768px)': {
      flexBasis: '100%',
      padding: '20px',
    },
  },
  mintNftHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
  },
  heading: {
    color: '#323441',
    fontSize: '36px',
    fontFamily: 'Lato',
    fontWeight: '900',
    margin: '0px',
  },
  authLink: {
    color: '#555555',
    fontSize: '18px',
    fontFamily: 'Lato',
    margin: '0px',
  },
  formContainer: {
    marginTop: '30px',
  },
  formFooter: {
    marginTop: '30px',
  },
  btnLabel: {
    color: '#5B5D68',
    fontSize: '18px',
    fontFamily: 'Lato',
    margin: '0px',
  },
  btnWrappaer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '12px',
  },
  mintBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '12px',
    background: 'linear-gradient(0deg, #444444, #666666)',
    borderRadius: '10px',
    border: 'none',
    outline: 'none',
    width: '186px',
    height: '57px',
    color: '#FCFEFF',
    fontFamily: 'Nunito',
    fontWeight: 'bold',
    fontSize: ' 18px',
    cursor: 'pointer',
  },
  availabilityInfo: {
    margin: '15px 0',
    padding: '10px',
    backgroundColor: 'rgba(85, 85, 85, 0.1)',
    borderRadius: '5px',
    '& p': {
      margin: '0',
    },
  },
  collectionImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 0',
    width: '100%',
  },
  collectionImage: {
    width: '300px',
    height: '300px',
    objectFit: 'cover',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
  },
  statementNote: {
    fontSize: '12px',
    color: '#666',
    marginLeft: '20px',
    marginTop: '4px',
    fontStyle: 'italic',
  },
  errorMessage: {
    color: '#ff0000',
    fontSize: '14px',
    marginTop: '10px',
    padding: '8px',
    backgroundColor: 'rgba(255, 0, 0, 0.1)',
    borderRadius: '4px',
    textAlign: 'center',
  },
}));

export { usePreSaleStyles };
