import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import { Dialog, DialogTitle, DialogContent, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import * as Yup from 'yup';
import { isEmpty } from 'lodash';

import useCompany from 'Hooks/useCompany';
import { registerWithFirebase } from 'Store/actions';
import { listSubscriptions, addSmartContract } from 'Services/api';
import firebaseService from 'Services/firebaseService';
import PageLayout from 'Layout/Layout';
import SectionLayout from 'Layout/SectionLayout';
import GlobalButton from 'Shared/Button';
import Input from 'Shared/Input';
import Select from 'Shared/SelectField';
import Container from 'Components/Container';
import Loading from 'Components/Loading';
import InPageSignupLogin from 'Components/InPageSignupLogin';

import Setting from './assets/setting.png';
import { useStyles } from './style';

const signupScheme = Yup.object().shape({
  companyName: Yup.string().required('Provide your organization or project name'),
  teamSize: Yup.number().typeError('Must be a number').required('Provide your team size'),
  companyDescription: Yup.string().required('Provide reason for NFTs'),
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ApiSignup = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const plan = queryParams.get('plan');

  const dispatch = useDispatch();
  const user = useSelector(state => state.user);

  const [showStatus, setShowStatus] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(false);
  const [signupValid, setSignupValid] = useState(false);
  const [signupValues, setSignupValues] = useState({});
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const { addCompany, getCompany, updateCompany } = useCompany();

  useEffect(() => {
    window.gtag('event', 'begin_checkout', {
      send_to: 'AW-643218609/1DU_CPm0y9ABELH52rIC',
      value: 100,
      currency: 'USD',
      transaction_id: '',
    });
  }, []);

  const handleSnackBarOpen = () => {
    setSnackBarOpen(true);
  };

  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackBarOpen(false);
  };

  const onSignupLoginValidationChange = (isValid, formValues) => {
    setSignupValid(isValid);
    setSignupValues(formValues);
  };

  const registerUser = async ({ firstName, lastName, email, password, phone, username }) => {
    try {
      const isUsernameDuplicated = await firebaseService.isUsernameDuplicated(username);
      if (isUsernameDuplicated) {
        setErrorMessage('Username is in use');
        return 'Username is duplicated';
      }

      const registrationResult = await dispatch(
        registerWithFirebase({
          firstName: user?.firstName || firstName,
          lastName: user?.lastName || lastName,
          username: user?.username || username,
          email: user?.email || email,
          password: user?.password || password,
          phone: user?.phone || phone,
        }),
      ).then(result => result);

      if (registrationResult.payload?.uid) {
        return registrationResult.payload.uid;
      } else if (
        registrationResult.payload.includes &&
        registrationResult.payload.includes('auth/email-already-in-use')
      ) {
        setErrorMessage('Email already in use');
        return 'auth/email-already-in-use';
      } else {
        throw new Error(registrationResult.payload);
      }
    } catch (err) {
      console.log('User Registration error: ', err);
      throw new Error('User Registration error: ' + err.message);
    }
  };

  const getSubscriptions = async () => {
    setLoading(true);
    const data = await listSubscriptions();
    setSubscriptions(data.map(item => ({ value: item.id, label: item.name })));
    setLoading(false);
  };

  useEffect(() => {
    getSubscriptions();
  }, []);

  useEffect(() => {
    const fetchCurrentCompany = async () => {
      const result = await getCompany();
      setCompany(result);
    };

    if (user) {
      fetchCurrentCompany();
    }
  }, [getCompany, user]);

  return (
    <PageLayout hasContainer={false}>
      <SectionLayout>
        <Container className={classes.pageWrapper}>
          {loading && (
            <div className={classes.loading}>
              <Loading
                type={'bubbles'}
                color={'#2bc8c5'}
                height={'80px'}
                width={'80px'}
                containerHeight={'70vh'}
                containerWidth={'100%'}
                message={'Loading...'}
              />
            </div>
          )}

          <div className={classes.pageContainer}>
            <div className={`${classes.flex48} ${classes.apiKeyContainer}`}>
              <h1 className={classes.apiKeyHeading}>Software as a Service Signup</h1>

              <p className={classes.apiDesc}>
                User our fully online software to start building your NFT project today.
              </p>
              <p className={classes.apiDesc}>
                <a href="https://api.ownerfy.com" target="new">
                  Ownerfy Application Programming Interface (API) Documentation
                </a>
              </p>
              <p className={classes.apiDesc}>
                <a href="https://api.ownerfy.com" target="new">
                  Ownerfy Readme
                </a>
              </p>

              <img src={Setting} alt="Not found" className={classes.settingImg} />
            </div>

            <div className={`${classes.flex48} ${classes.formContainer}`}>
              <InPageSignupLogin onValidationChange={onSignupLoginValidationChange} />

              <h3 className={classes.title}>Information about you</h3>
              <Formik
                initialValues={{
                  companyName: company?.companyName,
                  teamSize: company?.teamSize,
                  companyDescription: company?.companyDescription,
                  planId: plan || 'subscription1',
                }}
                validationSchema={signupScheme}
                onSubmit={async (values, { resetForm }) => {
                  setLoading(true);
                  let userId = user?.userId || user?.uid;
                  if (!userId) {
                    userId = await registerUser(signupValues);
                    if (userId.includes('Username is duplicated')) {
                      setLoading(false);
                      handleSnackBarOpen();
                      return;
                    }
                    if (userId.includes('auth/email-already-in-use')) {
                      setLoading(false);
                      handleSnackBarOpen();
                      return;
                    }
                  }

                  resetForm();
                  const { teamSize } = values;

                  if (company?.id) {
                    const subscriptionData = await updateCompany({
                      ...values,
                      teamSize: Number(teamSize),
                      companyId: company.id,
                    });
                    if (subscriptionData) window.open(subscriptionData.url);
                  } else {
                    const { session, apiKey, isFree } = await addCompany({ ...values, teamSize: Number(teamSize) });
                    await addSmartContract({
                      name: user?.username || signupValues.username,
                      minterUserId: userId,
                      isDefault: true,
                      apiKey,
                    });
                    if (!isFree) window.open(session.url);
                  }

                  setShowStatus(true);
                  setTimeout(() => {
                    setShowStatus(false);
                    history.push('/api-usage');
                  }, 3000);
                }}
              >
                {({ errors, touched, setFieldValue, values }) => (
                  <Form>
                    <Input
                      label="Organization/Project"
                      name="companyName"
                      touched={touched.companyName}
                      error={errors.companyName}
                      value={values.companyName}
                      data-cy="company-name"
                    />
                    <Input
                      label="Team Size"
                      name="teamSize"
                      type="number"
                      touched={touched.teamSize}
                      error={errors.teamSize}
                      value={values.teamSize}
                      data-cy="team-size"
                    />
                    <Input
                      label="What are You Building with NFTs?"
                      name="companyDescription"
                      touched={touched.companyDescription}
                      error={errors.companyDescription}
                      value={values.companyDescription}
                      data-cy="company-description"
                    />
                    <Select
                      label="Plan"
                      name="planId"
                      options={subscriptions}
                      touched={touched.planId}
                      error={errors.planId}
                      value={values.planId}
                      data-cy="plan"
                      onChange={value => setFieldValue('planId', value)}
                    />
                    <div className={classes.loginBtnContainer}>
                      <div className={classes.btnWrapper}>
                        <GlobalButton
                          background
                          btnLabel="Submit"
                          data-cy="submit-button"
                          disabled={
                            !(
                              values.companyName &&
                              values.teamSize &&
                              values.companyDescription &&
                              values.planId &&
                              (signupValid || !isEmpty(user))
                            )
                          }
                        />
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            <Snackbar
              open={snackBarOpen}
              autoHideDuration={6000}
              onClose={handleSnackBarClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <Alert onClose={handleSnackBarClose} severity="error">
                {errorMessage}
              </Alert>
            </Snackbar>
          </div>

          <Dialog open={showStatus} onClose={() => setShowStatus(false)}>
            <DialogTitle>Organization Creation</DialogTitle>
            <DialogContent>API key creation successfull.</DialogContent>
          </Dialog>
        </Container>
      </SectionLayout>
    </PageLayout>
  );
};

export default ApiSignup;
